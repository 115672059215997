import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios, { formToJSON } from "axios";
import React, { Component } from "react";
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';

import { v4 as uuidv4 } from 'uuid';
import { CustomToggleListLightning } from "../customToggleList.js";
import { FileModal } from "./fileModal";
import { RestockHelp } from "./help";
import '../inventory.css';
import ItemPropertiesModal from '../ItemModal/itemModal.js';
import { columnsLinked } from "./linkedColumns";
import { addColumns, SalesColumns,removeRestockColumns,addRestockColumns } from "./SalesColumns";
import { withRouter } from '../withRouter';
import { MyDropzone } from './dropZone.js';

import { LightningTable } from '../../lightningTable/lightning-table';
import { LightningKit } from '../../lightningTable/lightning-kit';
import { LightningSearch } from '../../lightningTable/lightning-search';
import { LightningCSV } from '../../lightningTable/lightning-misc';

import { FilterMenu } from '../filters/filterMenu.js';
import { RestockFilters } from '../filters/restockFilters.js';

import {addToQueryString} from '../../utils/QueryStringModder'
import { handleCartAddCallback } from "../ItemModal/utilFunctions.js";

import {setRestockValues, restockStatusToColor} from './restockValues'
let endpoint = ""


const rowStyle = (row, rowIndex) => {
    const style = {minHeight:"88px"};
    return style;
};


const RestockToggle=({onChange, value, toggleProps, disabled})=>{

    const [stored_value,setValue] = React.useState(value)


    React.useEffect(()=>{
        //console.log("Here" ,stored_value,value)
        if(stored_value != value){
            onChange(value, toggleProps)
            setValue(value)
        }
    }, [value])

   return( <Form.Switch
    id="restock-switch"
    label="Restock Suggestions"
    style={{textAlign:"left", float:"right"}}
    checked={value}
    disabled={disabled}
    onChange={()=>{ setValue(!value);  onChange(!value, toggleProps)}}
    />)


}

class Restock extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        var salesColumns = SalesColumns
        var linkedColumns = columnsLinked
        salesColumns = addColumns(salesColumns, "total_wk", "Total ", false)
        salesColumns=addRestockColumns(salesColumns)
        linkedColumns = addColumns(linkedColumns, "mfn_wk", "Sales: ", true)
        this.state = {
            items: [],
            errorMessage: "",
            modalShow: false,
            selectedItem: "",
            modalError: "",
            existingItem: "",
            selectedQty:{},
            fileSelectShow: false,
            uploadedData: {},
            tableCols: salesColumns,
            subtableCols: linkedColumns,
            filteredItems: [],
            allItems: [],
            allItemsDaily: [],
            allItemsWeekly: [],
            loading: true,

            onlyZeroOnHand: false,
            onlyNoneOrder: false,
            onlyThisWeek: false,
            alertMessage: "",
            alertType: "",
            showAlert: false,
            onlyNegativeProfit: false,
            dailyReport: false,
            summary: {},
            expanded: [],
            useRestock:false,
            defaultToggleExclusions:['avg_weekly_store_sales_30','avg_weekly_store_sales_60','avg_weekly_store_sales_90','avg_weekly_fba_sales_30','avg_weekly_fba_sales_60','avg_weekly_fba_sales_90','item_lead_time','item_stock_weeks','daysLeft','to_order','status_num','fba_note', 'avg_weekly_store_sales_4w', 'avg_weekly_store_sales_8w', 'avg_weekly_store_sales_12w','avg_weekly_fba_sales_4w','avg_weekly_fba_sales_8w','avg_weekly_fba_sales_12w'],
            toggleExclusions:[],
            showOrderNow:true,
            showOrderSoon:true,
            showUnknown:true,
            showLowOnOrder:true,
            showGoodStock:true,
            showOverStock:true,
            showOutOfStock:true,
            showMoveToFBA:true,
            showSendToFBA:true,
            disabledList:[],
            refreshFilterCallback:0,
            defaultDisableList:["OrderNow", "OrderSoon", "LowOnOrder", "GoodStock", "Overstock", "MoveToFBA", "SendToFBA", "OutOfStock", "Unknown"]
        };
        this.handleColumnAddition = this.handleColumnAddition.bind(this);
        this.filterItems = this.filterItems.bind(this);
        this.updateItem = this.updateItem.bind(this);
        this.updateItemLocally = this.updateItemLocally.bind(this);
        this.updateItemQty = this.updateItemQty.bind(this);
        this.updateItemQtyLocally = this.updateItemQtyLocally.bind(this);
        this.afterSearch = this.afterSearch.bind(this)
        this.expandRow = this.expandRow.bind(this)
        this.handleCustom = this.handleCustom.bind(this)
        this.headerBoxRef = React.createRef()
        this.filterBoxRef = React.createRef()
    }

    componentWillUnmount() {
        if (this.controller) {
            this.controller.abort()
        }
        this._isMounted = false
    }

    afterSearch = (filter, newResult) => {
        this.setState({ searchWord: filter, filteredItems: newResult.map(row => { return row.original }) });
    }

    filterItems = (onlyNoneOrder, onlyThisWeek, onlyZeroOnHand, onlyNegativeProfit, GoodStock, LowOnOrder, MoveToFBA, OrderNow, OrderSoon, OverStock, OutOfStock, SendToFBA, Unknown) => {
        var tmpItems = [...this.state.allItems]
        if (onlyNoneOrder) {
            tmpItems = tmpItems.filter(function (item) {
                return item.on_order_qty === 0
            })
        }
        if (onlyThisWeek) {
            tmpItems = tmpItems.filter(function (item) {
                return item.total_wk0 > 0
            })
        }
        if (onlyZeroOnHand) {
            tmpItems = tmpItems.filter(function (item) {
                return item.item_qty === 0 && item.is_regular
        })
        }
        if (onlyNegativeProfit) {
            tmpItems = tmpItems.filter(function (item) {
                var today = new Date()
                return item.avg_profit < 0 && !item.dnr_ecom && !item.dnr_fba && (item.hide_until == "" || !Date.parse(item.hide_until) || Date.parse(item.hide_until) < today)
            })
        }

        var mask=0
        if(this.state.useRestock){
            if (GoodStock) {
                mask |=16
            }
            if(OverStock){
                mask|=32
            }

            if(OrderSoon){
                mask|=4
            }
            if(MoveToFBA){
                mask|=2
            }
            if(OrderNow){
                mask|=1
            }
            if(LowOnOrder){
                mask|=8
            }
            if(SendToFBA){
                mask|=64
            }
            if(OutOfStock){
                mask|=128
            }
            
            tmpItems = tmpItems.filter(function (item) {
               
                return (item.status_num & mask) || (Unknown && (item.status_num == 0 || item.status_num == 1024))
            })
        }
        
        this.setState({ items: [...tmpItems] , loading:false})
    }

    //callback(tmpStates,filter, subFilter )
    filterCallback = (allValues, updatedFilter, subFilterName) => {

        //console.log(allValues)
        //console.log(updatedFilter)
        //console.log(subFilterName)

        if (updatedFilter ===undefined || (updatedFilter.callbackName === "CustomFilter" || updatedFilter.callbackName === "RestockStatus")) {
            this.filterItems(allValues.onOrderQtyIs0, allValues.itemsSoldThisWeek, allValues.quantityIs0, allValues.profitIsNegative, allValues.GoodStock, allValues.LowOnOrder, allValues.MoveToFBA, allValues.OrderNow, allValues.OrderSoon, allValues.Overstock, allValues.OutOfStock, allValues.SendToFBA, allValues.Unknown)
        } else if (updatedFilter.callbackName === "ReportType") {
            this.setState({ dailyReport: allValues.ReportType, items: [], loading: true }, () => this.getItem())

            if(allValues.ReportType){
               this.setState({useRestock:false})
            }
        }
        
    }


    onRowDoubleClick(row) {

        var selected_item = row
        var existing_Item = this.props.cart_items.filter(function (item) {
            return item.id === selected_item.group_id
        })[0]

        if(this.state.useRestock){
            this.setState({ selectedQty: {'total_to_order': row.to_order, 'ecom_to_order':row.to_order_ecom, 'fba_to_order': row.to_order_fba}})
        }else{
            this.setState({ selectedQty: {'total_to_order': "", 'ecom_to_order':"", 'fba_to_order': ""}})
        }

        if (!existing_Item) {
            existing_Item = ""
        }
        this.setState({
            modalShow: true,
            selectedItem: selected_item,
            existingItem: existing_Item,
            

        }, () => this.getItemByID(selected_item.group_id))
    }

    getItemByID = (group_id) => {
        let currentComponent = this;
        axios.get(endpoint + "/api/items?item-id=" + group_id,
            {
                headers: {
                    Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
                },
                signal: this.controller.signal
            }).then(res => {

                if (res.data) {

                    var items = res.data.map(item => {
                        var tmp = item.item_summary

                        tmp['linked_items'] = item.linked_items
                        return tmp;
                    })
                    if (items.length > 0) {
                        this.setState({ selectedItem: items[0] })
                    }
                }
            }).catch(error => {
                // handle error 
                if (axios.isCancel(error)) {
                    return;
                }
                console.log(error)
            })
    };

    getItem = () => {
        let currentComponent = this;
        this.setState({loading:true})

        if (currentComponent.state.dailyReport && this.state.allItemsDaily.length > 0) {

            this.setState({ allItems: this.state.allItemsDaily, loading: false ,refreshFilterCallback: this.state.refreshFilterCallback+1})
            return;
        } else if (!currentComponent.state.dailyReport && this.state.allItemsWeekly.length > 0) {
            this.setState({ allItems: this.state.allItemsWeekly, loading: false ,refreshFilterCallback: this.state.refreshFilterCallback+1})
            return
        } else {

            var url = "/api/get-order-data?days=" + (currentComponent.state.dailyReport ? "1" : "0")
            axios.get(endpoint + url,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
                    }, signal: this.controller.signal
                }).then(res => {
                    if (res.data) {
                        if (currentComponent.state.dailyReport) {
                            this.setState({ allItemsDaily: res.data })
                        } else {
                            this.setState({ allItemsWeekly: res.data})
                        }
                        var tmpdata = [...res.data]
                        tmpdata.map(e => {
                            var ret = setRestockValues(e)
                            Object.assign(e, { to_order: ret.order })
                            Object.assign(e, { to_order_ecom: ret.ecom_order })
                            Object.assign(e, { to_order_fba: ret.fba_order })
              
                            Object.assign(e, { status: ret.status })
                            Object.assign(e, { daysLeft: ret.days_left })
                            Object.assign(e, { status_num: ret.status_num })
                            Object.assign(e, {status_reason:ret.status_reason})
                          })
                          //console.log("Flipping filter")
                        this.setState({
                            
                            items:tmpdata,

                            allItems: tmpdata,
                            errorMessage: "",
                            tableCols: this.state.tableCols.map(e => (e.headerAttrs &&
                                e.headerAttrs.index != undefined) ?
                                Object.assign({}, e, { headerAttrs: { index: e.headerAttrs.index, title: res.data[0].wk_names[e.headerAttrs.index] } }) : e),
                            subtableCols: this.state.subtableCols.map(e => (e.headerAttrs &&
                                e.headerAttrs.index != undefined) ?
                                Object.assign({}, e, { headerAttrs: { index: e.headerAttrs.index, title: res.data[0].wk_names[e.headerAttrs.index] }, text: "Sales: " + res.data[0].wk_names[e.headerAttrs.index] }) : e)
                                ,refreshFilterCallback: this.state.refreshFilterCallback+1})

                    } else {
                        currentComponent.setState({
                            items: [],
                            allItems: [],
                            errorMessage: "Unable to load orders. Please try again later.", loading: false

                        });
                    }
                }).catch(error => {
                    // handle error 
                    if (axios.isCancel(error)) {
                        return;
                    }
                    currentComponent.setState({
                        items: [],
                        allItems: [],
                        errorMessage: "Unable to load orders. Please try again later.", loading: false
                    });
                    if (error.response) {
                        if (error.response.status === 401) {
                            this.props.setLogcallback("", "")
                            this.props.logincallback("Error! You have been logged out. Please log back in")
                            currentComponent.props.navigate('/index.html')
                        }
                    } else {
                        currentComponent.setState({
                            items: [],
                            errorMessage: "Unable to load orders. Please try again later."
                        });
                    }
                });
        }
    };

    componentDidMount() {
        this.controller = new AbortController();
        this._isMounted = true;
       
        const params = this.props.searchParams
        var _onlyZeroOnHand=false
        var _onlyNegativeProfit=false
        var _onlyNoneOrder = false
        if (params.get('onlyZeroOnHand') && params.get('onlyZeroOnHand') === 'true') {            
            _onlyZeroOnHand=true
        }
        if (params.get('onlyNegativeProfit') && params.get('onlyNegativeProfit') === 'true') {
            _onlyNegativeProfit=true
        }
        if (params.get('onlyNoneOnOrder') && params.get('onlyNoneOnOrder') === 'true') {
            _onlyNoneOrder=true
        }
        

        var counter = 0
        if (params.get('showOrderNow') && params.get('showOrderNow') === 'true') {
            counter+=1
        }
        if (params.get('showLowOnOrder') && params.get('showLowOnOrder') === 'true') {
            counter+=1
        }
        if (params.get('showOrderSoon') && params.get('showOrderSoon') === 'true') {
            counter+=1
        }
        if (params.get('showGoodStock') && params.get('showGoodStock') === 'true') {
            counter+=1
        }
        if (params.get('showOverStock') && params.get('showOverStock') === 'true') {
            counter+=1
        }
        if (params.get('showMoveToFBA') && params.get('showMoveToFBA') === 'true') {
            counter+=1
        }
        if (params.get('showSendToFBA') && params.get('showSendToFBA') === 'true') {
            counter+=1
        }
        if (params.get('showOutOfStock') && params.get('showOutOfStock') === 'true') {
            counter+=1
        }

        var  _showOrderNow=true
        var  _showOrderSoon=true
        var _showLowOnOrder=true
        var _showGoodStock=true
        var _showOverStock=true
        var _showMoveToFBA=true
        var _showSendToFBA=true
        var _showOutOfStock=true
        var _showUnknown = true

        var _useRestock=false
        var _disabledList=this.state.defaultDisableList 
        if(counter > 0){
              _showOrderNow=false
            _showOrderSoon=false
            _showLowOnOrder=false
            _showGoodStock=false
            _showOverStock=false
            _showMoveToFBA=false
            _showSendToFBA=false
            _showOutOfStock=false
            _showUnknown = false
         
            if (params.get('showOrderNow') && params.get('showOrderNow') === 'true') {
                _showOrderNow=true
            }
            if (params.get('showLowOnOrder') && params.get('showLowOnOrder') === 'true') {
                _showLowOnOrder=true
            }
            if (params.get('showOrderSoon') && params.get('showOrderSoon') === 'true') {
                _showOrderSoon=true
            }
            if (params.get('showGoodStock') && params.get('showGoodStock') === 'true') {
                _showGoodStock=true
            }
            if (params.get('showOverStock') && params.get('showOverStock') === 'true') {
                _showOverStock=true
            }
            if (params.get('showMoveToFBA') && params.get('showMoveToFBA') === 'true') {
                _showMoveToFBA=true
            }
            if (params.get('showSendToFBA') && params.get('showSendToFBA') === 'true') {
                _showSendToFBA =true
            }
            if (params.get('showOutOfStock') && params.get('showOutOfStock') === 'true') {
                _showOutOfStock=true
            }
        }

      

        if (params.get('restock') && params.get('restock') === 'true'){
                _useRestock=true
                _disabledList=[]
        }
        this.setState({ onlyZeroOnHand: _onlyZeroOnHand,
            onlyNegativeProfit: _onlyNegativeProfit,
            onlyNoneOrder: _onlyNoneOrder,
            showOrderNow:_showOrderNow,
            showOrderSoon:_showOrderSoon,
            showLowOnOrder:_showLowOnOrder,
            showGoodStock:_showGoodStock,
            showOverStock:_showOverStock,
            showOutOfStock:_showOutOfStock,
            showMoveToFBA:_showMoveToFBA,
            showSendToFBA:_showSendToFBA,
            showUnknown:_showUnknown,
            useRestock:_useRestock,
            disabledList:_disabledList,
            toggleExclusions:this.state.defaultToggleExclusions},()=>this.getItem())
        
    }

    uploadPartNumbers(items) {

        axios.post(endpoint + '/api/update-part-numbers',
            items, {
            headers: {
                Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
            }
        })
    }


    updateItemLocally(cols, item, remoteExecutionSuccess, callback = ()=>{}) {

        var newItems= this.state.items.map(e => {
            if (e.group_id === item.group_id) {
              for (var i = 0; i < cols.length; i++) {
                e[cols[i]] = item[cols[i]]
              }
            }
            return e;
        })

        var newItemsAll= this.state.allItems.map(e => {
            if (e.group_id === item.group_id) {
              for (var i = 0; i < cols.length; i++) {
                e[cols[i]] = item[cols[i]]
              }
            }
            return e;
        })

        if (this._isMounted) {
            if (remoteExecutionSuccess) {
                this.setState({
                    showAlert: true,
                    items: [...newItems],
                    allItems: [...newItemsAll],

                    alertMessage: "Successfully Updated Item",
                    alertType: "success"
                }, ()=>callback())
                this.props.refreshTags()
            } else {
                this.setState({
                    showAlert: true,
                    alertMessage: "Unable To Update Items; Server Error",
                    alertType: "danger"
                })
            }
        }

    }

    updateItem(cols, item, callback) {
       
        var com = this

        var payload = { updated_fields: cols, updated_item: item }
        axios.put(endpoint + '/api/update-item/' + item.description_id,
            payload, {
            headers: {
                Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
            }
        })
            .then(function (response) {
                com.updateItemLocally(cols, item, true, callback)

            })
            .catch(function (error) {
                com.updateItemLocally(cols, item, false, callback)
            });

    }



    updateItemQtyLocally(payload, remoteExecutionSuccess){
        if (this._isMounted) {
            if(remoteExecutionSuccess){
                this.setState({
                    items: this.state.items.map(e => (e.group_id === payload[0].item_id ? Object.assign({}, e, { item_qty: payload[0].item_qty + payload[0].item_qty_delta }) : e)),
                    showAlert: true,
                    alertMessage: "Successfully Updated Item Qty",
                    alertType: "success"
                })
            }else{
                if (this._isMounted) {
                    this.setState({
                        showAlert: true,
                        alertMessage: "Unable To Update Item Qty; Server Error",
                        alertType: "danger"
                    })
                }
            }
        }
    }
    updateItemQty(payload) {
        var component = this
        //console.log(payload)
        axios.post(endpoint + '/api/update-item-qty',
            payload, {
            headers: {
                Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
            }
        })
            .then(function (response) {
                component.updateItemQtyLocally(payload, true)
            })
            .catch(function (error) {
                component.updateItemQtyLocally(payload, false)
            });
    }


    //data=({data: props.data, part_col:selected_part_col, w1_col:selected_w1_col, w2_col:selected_w2_col})
    handleColumnAddition(data, toggleProps) {

        this.setState({
            fileSelectShow: false
        })

        var tmpVis = {...toggleProps.columnVisibility}
        tmpVis['w1']=true
        tmpVis['w2']=true
        toggleProps.setColumnVisibility(tmpVis)

        var my_items = [...this.state.items]
        var updated_items = []
        for (let index = 0; index < my_items.length; ++index) {
            my_items[index].w1 = -1
            my_items[index].w2 = -1
            data.data.rows.forEach(function (entry) {
                var part_num = entry[data.part_col]
                var item = my_items[index]
                var found = false

                if (item.item_part_num != "" && part_num) {
                    if (part_num.toString().toLowerCase() == item.item_part_num.toString().toLowerCase()) {
                        found = true
                    }
                } else if (item.item_sku != "" && part_num) {
                    var name_words = item.item_sku.split(" ")
                    name_words.forEach(function (word) {
                        if (part_num.toString().toString().toLowerCase() == word.toLowerCase() && word.length > 4) {
                            found = true
                        }
                    })
                }
                if (found) {
                    
                    if (data.w1_col >= 0) {
                        my_items[index].w1 = entry[data.w1_col]
                    }
                    if (data.w2_col >= 0) {
                        my_items[index].w2 = entry[data.w2_col]
                    }
                    updated_items.push({ description_id: item.description_id, item_part_num: part_num })
                    //console.log("Found", item, my_items[index])
                }
            });

        }
        this.setState({
            items: my_items
        })
        this.uploadPartNumbers(updated_items)
    }


    expandRow(row) {
        
        return (
            <div style={{ marginLeft: "20px" }}>
                <LightningKit columns={this.state.subtableCols}
                    getRowCanExpand={() => false}
                    in_data={this.state.items.filter(function (item) {
                        return item.group_id == row.original.group_id
                    })[0].selling_items}
                    width="100%" >
                    {
                        props => (
                            <LightningTable className="smallFont" height="100%" maxheight="500px" {...props} {...props.table} noDataIndication="No Data to Show" />
                        )}

                </LightningKit>
            </div>
        )
    }

    options = {
        paginationSize: 5,
        sizePerPage: 100,
        pageStartIndex: 0,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page

        showTotal: true,
        sizePerPageList: [{
            text: '50', value: 50
        }, {
            text: '100', value: 100
        }, {
            text: '500', value: 500
        }] // A numeric array is also available. the purpose of above example is custom the text
    };

    handleCustom(typed_val) {
        var selected_item = {}
        if (this.state.filteredItems.length < 1) {
            selected_item = { group_id: uuidv4(), item_name: "Custom Item: " + typed_val, item_sku: typed_val, custom: 1 }

        } else {
            selected_item = this.state.filteredItems[0]
        }
        this.onRowDoubleClick(selected_item)
    }


    toggleRestock(newValue, toggleProps){
        //console.log("Flipping filter toggle")
        this.setState({useRestock:newValue})
        if(this.state.refreshFilterCallback!=0){
            this.setState({refreshFilterCallback:this.state.refreshFilterCallback+1})
        }
        if(newValue){
            //if we now want to show restock stuff
            var tmpVis = {...toggleProps.columnVisibility}
            tmpVis['avg_weekly_store_sales_30']=false
            tmpVis['avg_weekly_store_sales_60']=false
            tmpVis['avg_weekly_store_sales_90']=false
            tmpVis['avg_weekly_fba_sales_30']=false
            tmpVis['avg_weekly_fba_sales_60']=false
            tmpVis['avg_weekly_fba_sales_90']=false
            tmpVis['item_lead_time']=false
            tmpVis['item_stock_weeks']=false
            tmpVis['daysLeft']=false
            tmpVis['to_order']=false
            tmpVis['status_num']=true
            tmpVis['fba_note']=false

            
            toggleProps.setColumnVisibility(tmpVis)
            this.setState({toggleExclusions:[], disabledList:[]})
        }else{
            var tmpVis = {...toggleProps.columnVisibility}
            tmpVis['avg_weekly_store_sales_30']=false
            tmpVis['avg_weekly_store_sales_60']=false
            tmpVis['avg_weekly_store_sales_90']=false
            tmpVis['avg_weekly_fba_sales_30']=false
            tmpVis['avg_weekly_fba_sales_60']=false
            tmpVis['avg_weekly_fba_sales_90']=false
            tmpVis['item_lead_time']=false
            tmpVis['item_stock_weeks']=false
            tmpVis['daysLeft']=false
            tmpVis['to_order']=false
            tmpVis['status_num']=false
            tmpVis['fba_note']=false
            toggleProps.setColumnVisibility(tmpVis)
            this.setState({toggleExclusions:this.state.defaultToggleExclusions, disabledList:this.state.defaultDisableList})
        }
        
        const iter = this.props.searchParams.entries()

       var res=addToQueryString(iter, "restock", newValue)
        
        this.props.setSearchParams(res)
    }


    

    render() {

        return (


            <div className="wrapper fullPageWrapper">
                <Alert show={this.state.showAlert} variant={this.state.alertType} onClose={() => this.setState({ showAlert: false })} dismissible>
                    {this.state.alertMessage}
                </Alert>
                <div className="customtable widetable">

                    <LightningKit columns={this.state.tableCols} pageOptions={this.options} getRowCanExpand={() => true} expandRow={this.expandRow} in_data={this.state.loading ? [] : this.state.items} afterFilter={this.afterSearch} width="100%" 
                    onEditCallback={(newValue, oldValue, row, column,) => {
                        var id = column.id
                        if (oldValue != newValue) {
                            var data = {}
                            if (id != "fba_note"){
                              data[id] = parseInt(newValue)
                              if (Number.isNaN(data[id])) {
                                row.original[id] = oldValue
                                return;
                              }
                            }else{
                              data[id] = newValue
                            }
                            data['description_id'] = row.original.description_id;
                            data['group_id'] = row.original.group_id;
                            row.original[id] = data[id]

                            this.updateItem([id], data, ()=>{
                                var ret = setRestockValues(row.original)
                                this.setState({
                                items: this.state.items.map(e => (e.group_id === data['group_id'] ? Object.assign({}, e, { to_order: ret.order }, { to_order_ecom: ret.ecom_order }, { to_order_fba: ret.fba_order }, { status: ret.status }, { daysLeft: ret.days_left }, {to_send: ret.to_send}) : e)),
                                allItems: this.state.allItems.map(e => (e.group_id === data['group_id'] ? Object.assign({}, e, { to_order: ret.order }, { to_order_ecom: ret.ecom_order }, { to_order_fba: ret.fba_order }, { status: ret.status }, { daysLeft: ret.days_left }, {to_send: ret.to_send}) : e)),
                                })
                            })
                        }
                    }}>
                        {
                            props => (
                                <div>
                                    <div className="headerBox"  ref={this.headerBoxRef}>
                                        <Container fluid>
                                            <Row>
                                                <Col xs={8}>
                                                    <h1 className=" left-align">{this.state.dailyReport ? "Daily " : "Weekly "} Inventory Sales Data</h1>
                                                </Col>
                                                <Col xs={4}>
                                                    <CustomToggleListLightning columns={props.table.getAllLeafColumns()} exclusions={this.state.toggleExclusions}/>
                                                    <RestockHelp />
                                                    <LightningCSV style={{ float: "right", marginRight: 15 }} text={<FontAwesomeIcon className="trashIcon" icon={faDownload} />} filename="Inventory-Sales.csv" table={props.table} title="Download CSV"></LightningCSV>
                                                    <FilterMenu inputFilters={RestockFilters({  "itemsSoldThisWeek": ()=>this.state.onlyThisWeek, "quantityIs0": ()=>this.state.onlyZeroOnHand, "profitIsNegative":()=>this.state.onlyNegativeProfit, "ReportType": false,"orderNow":()=>this.state.showOrderNow, "orderSoon":()=>this.state.showOrderSoon, "lowOnOrder":()=>this.state.showLowOnOrder, "goodStock":()=>this.state.showGoodStock, "overStock":()=>this.state.showOverStock, "outOfStock":()=>this.state.showOutOfStock, "moveToFBA":()=>this.state.showMoveToFBA,"onOrderQtyIs0": ()=>this.state.onlyNoneOrder, "sendToFBA":()=>this.state.showSendToFBA, "unknown":()=>this.state.showUnknown })} filterCallback={this.filterCallback} disabledList={this.state.disabledList} refreshFilterCallback={this.state.refreshFilterCallback}/>
                                                </Col>
                                            </Row>
                                        </Container>

                                    </div>
                                    <div id="table">
                                        <div className="grayheader headerBox" ref={this.filterBoxRef}>
                                            <Container fluid >
                                                <Row>
                                                    <Col lg={4} md={6} style={{ marginBottom: 5 }}>
                                                        <div id="search" >
                                                            <h2>Search:</h2>
                                                            <LightningSearch  {...props.searchProps} onEnterFunc={(e) => this.handleCustom(e)} />
                                                        </div>

                                                    </Col>
                                                    <Col lg={3} md={6} sm={12} style={{ marginBottom: 5 }}>
                                                        <MyDropzone setData={(set_name, set_cols, set_rows) => this.setState({
                                                            uploadedData: { name: set_name, cols: set_cols, rows: set_rows },
                                                            fileSelectShow: true
                                                        })} />
                                                    </Col>
                                                    <Col>
                                                    
                                                    <RestockToggle onChange = {(val, tp)=>this.toggleRestock(val, tp)} value={this.state.useRestock} toggleProps={props.toggleProps} disabled={this.state.dailyReport}/>
                                                    </Col>


                                                </Row>
                                            </Container>
                                        </div>

                                        <LightningTable
                                            onRowDoubleClick={(row) => this.onRowDoubleClick(row.original)}
                                            onRowClick={(row) => {row.getToggleExpandedHandler()(); this.expandRow(row)}}
                                            rowStyle={rowStyle}
                                            className="lightningFixedTable mediumFont "
                                            {...props} {...props.table}
                                            height={`calc(100vh - ${props.footerRef.current ? props.footerRef.current.clientHeight : 0}px - ${props.headerRef.current ? props.headerRef.current.clientHeight : 0}px - ${this.headerBoxRef.current ? this.headerBoxRef.current.clientHeight : 0}px - ${this.filterBoxRef.current ? this.filterBoxRef.current.clientHeight : 0}px - 143px`}
                                            noDataIndication={this.state.errorMessage!=="" ? this.state.errorMessage : (props.table.getCoreRowModel().rows.length > 0 && !this.state.loading) || (this.state.items.length == 0 && !this.state.loading) ? <div>{this.state.errorMessage} No result found for {this.state.searchWord}. <Button style={{ padding: "0px", marginTop: "-3px" }} variant="link" onClick={()=>this.handleCustom(this.state.searchWord)}>Click Here</Button> to add it to the cart as a custom item.</div> : <Spinner animation="border" />}

                                        />
                                    </div>
                                    <ItemPropertiesModal
                                        show={this.state.modalShow}
                                        onHide={() => this.setState({
                                            modalShow: false,
                                            modalError: ""
                                        })}
                                        readOnly={false}
                                        item={this.state.selectedItem}
                                        handleCartAdd={(payload, ei) =>handleCartAddCallback(ei, payload, this.props.itemupdate, this.props.itemcallback)}

                                        my_ei={this.state.existingItem}
                                        showMergePanels={false}
                                        defaultTab='addcart'
                                        my_updateItem={this.updateItemLocally}
                                        updateItemQty={this.updateItemQtyLocally}
                                        token={this.props.token}
                                        my_qty={this.state.selectedQty}
                                        allTags={this.props.allTags}
                                        refreshTags={this.props.refreshTags}

                                    />
                                    <FileModal
                                        show={this.state.fileSelectShow}
                                        onHide={() => this.setState({
                                            fileSelectShow: false
                                        })}
                                        data={this.state.uploadedData}
                                        itemData={this.state.items}
                                        handleSubmit={(data)=>this.handleColumnAddition(data, props.toggleProps)}
                                    />
                                </div>
                            )
                        }

                    </LightningKit>

                  
                </div>




            </div>
        );
    }
}

export default withRouter(Restock);
